<template>
    <section>
        <div class="gold-circle">
            <div class="row">
                <div class="col-md-12 d-flex justify-content-end text-right">
                    <div>
                        <h1>Services</h1>
                        <p class="mt-5">Pour réserver une séance de soin</p>
                        <button class="btn btn-primary"> Réserver</button>
                        <p class="mt-5">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius corporis, consequatur libero itaque cum, perspiciatis aspernatur omnis delectus cumque tempore laborum eum vel quaerat! Saepe ratione tempore sequi veritatis consequuntur!
                        </p>
                        <hr class="gold-yellow mt-5">
                    </div>
                </div>
            </div>

            <!-- liste des services -->
            <div class="row">
                <div class="col-md-3 p-1 img-container black-hover" v-b-modal.reservation v-for="item in products" :key="item.id">
                    <img class="img-services" src="@/assets/images/import/cosmetique.png" alt="">
                    <div class="top"> <h2 class="gold-yellow">Test</h2> </div>
                    <div class="bottom"> <p class="gold-yellow">Prestation et tarifs</p> </div>
                </div>

                <b-modal id="reservation" title="BootstrapVue" hide-footer hide-header size="400">
                    <h1 class="text-center">Epilations</h1>
                    <hr>
                    <p class="text-center text-muted">Les épilations sont réalisées à la cire tiède et chaude jetable.</p>
                    <div class="mt-3 d-flex justify-content-between" v-for="item in products" :key="item.id">
                        <div>
                            <h5>Sourcil</h5>
                            <p class="text-muted">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                        </div>
                        <div>5€</div>
                    </div>
                    <div class="d-flex justify-content-center mt-5">
                        <button class="btn btn-gradient-primary" @click="reserve()">Réserver une séance</button>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <!-- <img class="img-60" src="@/assets/images/import/hornement.png" alt=""> -->
                    </div>
                </b-modal>
            </div>
        </div> <!-- /content-wrapper-->
    </section>
</template>

<script>
import products from '../../pages/dashboard/json/products-reference-data.json'
export default {
    name: 'services-meb',
    data () {
        return {
            products: products
        }
    }
}
</script>

<style scoped>
.gold-circle {
    position: relative;
    min-height: 30rem;
    background-image: url('../../assets/images/import/boule_doree.png');
    background-size: 25rem;
    background-position: top -6rem left;
    background-repeat: no-repeat;
}
.img-services {
    width: 100%;
    min-height: 15rem;
    object-fit:cover
}

.img-container {
    position: relative;
    text-align: center;
}
.top {
  position: absolute;
  top: 4rem;
  left: 6rem
}
.bottom {
  position: absolute;
  bottom: 8px;
  left: 6rem
}

img {
    filter: grayscale(1) brightness(40%)
}
img {
    clip-path: start;
    transition: clip-path 1s;
}
.black-hover img:hover {
    filter: blur(4px);
    transform: scale(1.01);
    cursor: pointer;
}
.black-hover:hover {
    color: black !important;
}

/* modal */
.modal-dialog{
    /* max-width: 70% !important */
}

div.modal .modal-dialog.modal-400 {
  width: 400px !important
}

.img-60 {
    max-width: 60% !important;
}
</style>